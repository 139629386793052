import { defaultObject, setParsedError } from '@dometech-group/dometech-packages';
import { useState } from 'react';

export const REQUIRED_MSG = 'Ce champ est obligatoire';
function useFormErrors() {
	const [errors, setErrors] = useState(defaultObject);

	const handleError = (name, value) => {
		setErrors(prev => setParsedError({ ...prev }, name, value));
	};

	const handleInvalid = e => handleError(e.target.name, REQUIRED_MSG);

	const handleClearMsg = name => {
		if (name) {
			setErrors(prev => deleteParsedError(prev, name));
		} else {
			if (errors !== defaultObject) {
				setErrors(defaultObject);
			}
		}
	};

	return { errors, setErrors, handleError, handleInvalid, handleClearMsg };
}

const deleteParsedError = (errors, name) => {
	let haystack = errors;
	const nameStack = name.split('.');

	for (let i = 0; i < nameStack.length; i++) {
		haystack = { ...haystack };
		const part = nameStack[i];
		const match = part.match(/^(\w+)\[(\d+)\]$/);
		if (match) {
			const [, arrayName, index] = match;
			if (!haystack[arrayName]) break;
			if (!haystack[arrayName][index]) break;
			haystack[arrayName] = haystack[arrayName].slice();

			if (i === nameStack.length - 1) {
				delete haystack[arrayName][index];
			} else if (typeof haystack[part] === 'object') {
				haystack = haystack[arrayName][index];
			} else {
				break;
			}
		} else {
			if (haystack[part] === undefined) break;

			if (i === nameStack.length - 1) {
				delete haystack[part];
			} else if (typeof haystack[part] === 'object') {
				haystack = haystack[part];
			} else {
				break;
			}
		}
	}

	return errors;
};

export default useFormErrors;
